<template>
	<section class="w-full px-4 my-6">
		<h5 class="pb-4 text-xl font-bold uppercase captialize">
			About
		</h5>
		<div
			class="mb-2 text-sm"
			v-html="productDescription"
		/>
		<div
			v-if="hasPharmaComposition"
			class="flex items-center mb-6 space-x-3 text-sm font-bold"
		>
			<span
				v-for="(pharma, index) in mappedPharmaComposition"
				:key="index"
				class="uppercase"
			>
				{{ pharma.label }}:
				<span class="font-normal text-gray-500 normal-case">
					{{ pharma.amount }}%
				</span>
			</span>
		</div>
		<SocialShare class="my-4">
			<template #default="{ share }">
				<button
					type="button"
					class="h-10 px-2 transition-all duration-100 ease-in-out bg-white border border-gray-300 rounded-lg shadow focus:outline-none hover:shadow-lg focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
				>
					<span
						class="flex items-center w-full"
						@click="share"
					>
						<img
							src="@/assets/icons/share-alternate.svg"
							alt="social share"
							height="25"
							width="25"
							class="mx-1"
						>
						<span class="w-full mx-1 text-sm font-bold text-black uppercase">
							Share
						</span>
					</span>
				</button>
			</template>
		</SocialShare>
	</section>
</template>

<script async>
import sanitizePharmaComposition from '@/utils/mappers/sanitize-pharma-composition.js'

export default {
	components: {
		SocialShare: () => import('@/components/multiUse/SocialShare.vue')
	},
	props: {
		product: {
			type: Object,
			required: true
		},
		indication: {
			type: String,
			default: ''
		},
		pharmaComposition: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		productDescription() {
			return this.product?.description?.length ? this.product.description : 'No description available.'
		},
		hasPharmaComposition() {
			return !!this.pharmaComposition?.length
		},
		mappedPharmaComposition() {
			return sanitizePharmaComposition(this.pharmaComposition)
		}
	}
}
</script>

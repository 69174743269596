export const BUSINESS_TYPES = {
	DOCTOR: 'doctor',
	CLINIC: 'clinic',
	DISPENSARY: 'dispensary',
	DELIVERY: 'delivery',
	SMOKE: 'smoke',
	SEED: 'seed'
}

export const BUSINESS_TYPE_ICONS = {
	[BUSINESS_TYPES.DOCTOR]: 'doctors.svg',
	[BUSINESS_TYPES.CLINIC]: 'doctors.svg',
	[BUSINESS_TYPES.DISPENSARY]: 'shop.svg',
	[BUSINESS_TYPES.DELIVERY]: 'delivery.svg',
	[BUSINESS_TYPES.SMOKE]: 'smokeshops.svg',
	[BUSINESS_TYPES.SEED]: 'strain.svg' // using strain svg in lieu of seed svg existing
}

export const PICKUP_OR_DELIVERY = {
	PICKUP: 'pickup',
	DELIVERY: 'delivery'
}

export const BUSINESS_STATUSES = {
	OPEN: 0,
	CLOSED: 1,
	CLOSED_LEGACY: 2,
	UNLICENSED: 3
}

export const INDICATION_TYPES = {
	MED_AND_REC: 'both',
	MEDICAL: { value: 'medical', text: 'Medical' },
	RECREATIONAL: { value: 'recreational', text: 'Recreational' }
}

export const ORDER_LOGISTICS_TYPES = {
	PICKUP: { value: 'pickup', text: 'PICKUP' },
	DELIVERY: { value: 'delivery', text: 'DELIVERY' }
}

<template>
	<span>
		<img
			v-if="!hasSrc || showFallback"
			:src="fallbackOrError"
			:alt="alt"
			:class="[
				classes,
				'object-cover w-full h-full'
			]"
			:width="width"
			:height="height"
		>
		<picture
			v-else
			ref="thisImage"
			class="w-full"
		>
			<template v-if="shouldPrependWebp">
				<source
					:srcset="srcSetStringWebp"
					type="image/webp"
				>
			</template>
			<img
				:src="builtSrc"
				:class="[
					classes,
					objectCover ? 'object-cover w-full h-full' : 'w-full h-auto',
				]"
				:alt="alt"
				:width="width"
				:height="height"
				:fetchpriority="fetchPriority"
				@error="setFallbackImage"
			>
		</picture>
	</span>
</template>

<script defer>
import { mapMutations } from 'vuex'

import {
	BLOG_IMAGES,
	CHAIN_IMAGES,
	DEAL_IMAGES,
	GUIDE_IMAGES,
	LISTING_IMAGES,
	LOCATION_IMAGES,
	MENU_IMAGES,
	USER_IMAGES
} from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'
import { logError } from '@/utils/error-handling.js'

export default {
	props: {
		src: {
			type: [ String, Boolean ],
			default: ''
		},
		fallbackImage: {
			type: String,
			default: ''
		},
		classes: {
			type: String,
			default: ''
		},
		alt: {
			type: String,
			default: 'Where\'s Weed'
		},
		lazy: {
			type: Boolean,
			default: true
		},
		width: {
			type: Number,
			required: true
		},
		height: {
			type: Number,
			required: true
		},
		objectCover: {
			type: Boolean,
			default: false
		},
		imagePath: {
			type: String,
			default: '',
			validator: value => {
				return [
					'',
					BLOG_IMAGES,
					LISTING_IMAGES,
					MENU_IMAGES,
					LOCATION_IMAGES,
					DEAL_IMAGES,
					USER_IMAGES,
					GUIDE_IMAGES,
					CHAIN_IMAGES
				].includes(value)
			}
		},
		fuzzyLoad: {
			type: Boolean,
			default: false
		},
		fetchPriority: {
			type: String,
			default: 'auto'
		}
	},
	data() {
		return {
			observed: false,
			showFallback: false,
			fuzzyImageDenominator: 10,
			connectionSpeed: null,
			isMounted: false
		}
	},
	computed: {
		hasSrc() {
			return typeof this.src === 'string' && this.src?.length > 4
		},
		srcIsWebp() {
			return this.hasSrc && (this.src?.substring(this.src?.lastIndexOf('.'))?.toLowerCase() === 'webp')
		},
		shouldPrependWebp() {
			return this.imagePath && !this.srcIsWebp
		},
		builtSrc() {
			if (!this.hasSrc) return this.fallbackOrError
			if (this.fuzzyLoad && !this.observed) {
				return this.builtSrcFuzzy
			} else if (this.showFallback || (this.lazy && !this.observed)) return this.fallbackOrError
			return this.buildImageSrc({ width: this.width, height: this.height })
		},
		builtSrcFuzzy() {
			const width = this.width / this.fuzzyImageDenominator
			const height = this.height / this.fuzzyImageDenominator
			return this.buildImageSrc({ width, height })
		},
		srcSetStringWebp() {
			if (this.lazy && !this.observed) return ''
			return `${this.builtSrc}.webp`
		},
		fallbackOrError() {
			return this.fallbackImage || DEFAULT_WW_GRAY_LOGO
		}
	},
	watch: {
		builtSrc: {
			handler () {
				if (this.fetchPriority === 'high') {
					this.pushMetaLink({ rel: 'preload', href: this.builtSrc, as: 'image' })
				}
			},
			immediate: true
		}
	},
	mounted() {
		this.isMounted = true
		if (window && this.lazy && 'IntersectionObserver' in window) {
			this.observer()
		}
	},
	methods: {
		...mapMutations([ 'pushMetaLink' ]),
		setFallbackImage() {
			this.showFallback = true
		},
		buildImageSrc({ width, height }) {
			if (!this.imagePath) return this.src
			if (!this.hasSrc) return ''

			try {
				const srcClean = this.src.split('/').pop()
				return buildImageUrl({
					file: srcClean, imagePath: this.imagePath, width, height
				})
			} catch (e) {
				logError(e)
				return false
			}
		},
		observer() {
			if (!this.hasSrc) return
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.observed = true
						observer.unobserve(entry.target)
					}
				})
			})
			observer.observe(this.$refs.thisImage)
		}
	}
}
</script>

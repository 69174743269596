import { EIGHTH, G, HALF, HALF_G, QUARTER } from '@/constants/menu/product-sizes.js'

export const getPrice = ({ product, indication }) => {
	if (validatePricesOld(product)) {
		return product?.pricingOld?.find(price => price.type === indication && validatePriceValues(price))?.values || product?.pricingOld?.find(price => validatePriceValues(price))?.values
	}
	return product?.pricing?.find(price => price.type === indication)?.values || product?.pricing?.[0]?.values
}

export const hasPricing = ({ product, indication }) => {
	return product.pricing[indication] !== undefined &&
    product.pricing[indication] !== '0'
}

export const varToString = varObj => Object.keys(varObj)[0].toLowerCase()

export const formatUnit = unit => {
	switch (unit) {
		case varToString({ G }):
			return G
		case varToString({ HALF_G }):
			return HALF_G
		case varToString({ EIGHTH }):
			return `${EIGHTH} oz`
		case varToString({ QUARTER }):
			return `${QUARTER} oz`
		case varToString({ HALF }):
			return `${HALF} oz`
		default:
			return unit
	}
}

const isValidPricePerUnit = ({ product, indication }) => {
	return Array.isArray(product.pricingOld?.find(prices => prices?.type === indication)?.values) && indication ||
	Array.isArray(product.pricing?.find(prices => prices?.type === indication)?.values) && indication
}

export const getPricePerUnit = ({ product, indication, skipValidation = false }) => {
	if (!isValidPricePerUnit({ product, indication }) && !skipValidation) {
		return {}
	}
	if (product.pricingOld?.length) {
		return product.pricingOld.find(prices => prices.type === indication)?.values
			?.map(({ label, amount }, index) => ({
				label: label || 'ea',
				amount,
				index
			}))
			?.filter(({ amount }) => {
				return amount !== 0 && amount !== null && parseFloat(amount) > 0.00
			})
	} else {
		return product.pricing?.find(prices => prices.type === indication)?.values
			?.map(({ label, amount }, index) => ({
				label: label === '0' ? 'ea' : label || 'ea',
				amount,
				index: index
			}))
			?.filter(({ amount }) => {
				return amount !== 0 && amount !== null && parseFloat(amount) > 0.00
			})
	}
}

const validatePricesOld = product => {
	return !!product?.pricingOld?.length &&
		!product?.pricingOld?.every(price => price.values?.every(({ amount }) => amount === null))
}

const validatePriceValues = price => {
	return !price?.values?.every(({ amount }) => amount === null)
}

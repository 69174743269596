<template>
	<div class="w-full md:flex">
		<!-- START Mobile Title -->
		<div class="px-4 pb-4 md:hidden">
			<h4 class="px-0 text-3xl font-bold">
				{{ productName }}
			</h4>
			<div
				v-if="hasBrandName"
				class="w-full px-0 mr-1 font-bold text-gray-500 min-h-4 line-clamp-2"
			>
				{{ product.brand.name }}
			</div>
		</div>
		<!-- END Mobile Title -->
		<div class="w-full mx-auto mb-10 md:ml-4 md:mr-4 xl:mr-16 md:max-w-xs xl:max-w-sm md:mb-4">
			<!-- #TODO: Make this a carousel of images -->
			<div class="flex-shrink-0 w-full px-4 md:px-0">
				<WwImage
					:src="imageUrl"
					:width="400"
					:height="400"
					:alt="productName"
					:image-path="MENU_IMAGES"
					:lazy="false"
					fetch-priority="high"
					classes="rounded-lg border shadow-md border-gray-300"
				/>
			</div>
		</div>
		<section class="flex flex-col flex-grow w-full mx-0 md:w-1/2">
			<!-- START Desktop Title -->
			<div class="hidden pt-2 pb-4 ml-3 md:block">
				<h4 class="px-0 text-3xl font-bold">
					{{ productName }}
				</h4>
				<div
					v-if="hasBrandName"
					class="w-full px-0 mr-1 font-bold text-gray-500 line-clamp-2"
				>
					{{ product.brand.name }}
				</div>
			</div>
			<!-- END Desktop Title -->
			<ProductPricing
				v-if="hasPricing"
				:product="product"
				:indication="pricingIndication"
				:business-url="businessUrl"
				:business-id="businessId"
				:ordering-disabled="orderingDisabled"
				fade-visibility="hidden"
				scroller-class="py-2 pl-2 pr-3 md:pr-2 md:pl-1 xl:pl-0"
			/>
			<div
				v-if="orderingDisabled"
				class="px-3 my-2 text-left text-gray-500"
			>
				This product doesn't support online pre-ordering.
			</div>
		</section>
	</div>
</template>

<script async>
import WwImage from '@/components/multiUse/WwImage.vue'
import { INDICATION_TYPES } from '@/constants/business/index.js'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { getPricePerUnit } from '@/utils/pricing.js'

const { RECREATIONAL, MEDICAL } = INDICATION_TYPES
export default {
	components: {
		ProductPricing: () => import('@/components/product/ProductPricing.vue'),
		WwImage
	},
	props: {
		product: {
			type: Object,
			required: true
		},
		indication: {
			type: String,
			default: ''
		},
		imageUrl: {
			type: String,
			default: ''
		},
		businessUrl: {
			type: String,
			default: ''
		},
		disableOrdering: {
			type: Boolean,
			default: false
		},
		businessHasMedical: {
			type: Boolean,
			default: false
		},
		businessHasRecreational: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			MENU_IMAGES
		}
	},
	computed: {
		productName() {
			return this.product?.name || ''
		},
		hasBrandName() {
			return this.product?.brand?.name?.length
		},
		hasPricing() {
			return this.product?.pricing?.length
		},
		businessId() {
			return this.product?.listingId
		},
		orderingDisabled() {
			return !this.product?.hasOrdering || !this.hasPricing || this.disableOrdering || this.indicationMismatch
		},
		productIndication() {
			return this.product?.pricing?.[0]?.type || this.product?.pricingOld?.[0]?.type
		},
		pricingIndication() {
			if (this.indication) return this.indication
			if (this.product.recreational) return RECREATIONAL.value
			return MEDICAL.value
		},
		pricing() {
			return getPricePerUnit({
				product: this.product,
				indication: this.pricingIndication
			})
		},
		indicationMismatch() {
			const isMedicalProduct = this.productIndication === MEDICAL.value
			const isRecreationalProduct = this.productIndication === RECREATIONAL.value

			return isMedicalProduct && !this.businessHasMedical || isRecreationalProduct && !this.businessHasRecreational
		}
	}
}
</script>

/*
 * this is a helper to build urls for user uploaded images
 * $params file, width, height, imagePath, full
 * imagePath is the folder of the image (listing_images, location_images, user_images, menu_images, deal_images, etc)
 */

import { CONTENT_SERVER } from '@/constants/index.js'
import { logError } from '@/utils/error-handling.js'

const buildImageUrl = function ({ file, width, height, imagePath, full = false }) {
	const missingData = 'missing-data'
	if (!file) return
	try {
		if (!imagePath || ((!width || !height) && !full) || !file) {
			throw missingData
		}
		const folder = imagePath
		if (full === true) {
			return `${CONTENT_SERVER}/${folder}/full/${file}`
		} else {
			return `${CONTENT_SERVER}/${folder}/${width}x${height}/${file}`
		}
	} catch (e) {
		logError(e, { data: { file, width, height, imagePath } })
		return e.message
	}
}

export default buildImageUrl

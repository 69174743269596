export const destructureMenuProductGQL = function (product) {
	const {
		active,
		brand,
		desc: description,
		flowerType: species, // sativa, indica, hybrid
		hasImage,
		hasOrdering,
		id: productId,
		image,
		labStats: pharmaComposition,
		listingId,
		name,
		prices,
		pricesOld,
		subCategory: {
			name: subCategoryName
		},
		medical,
		recreational
	} = product

	return {
		active,
		brand,
		description,
		species,
		hasImage,
		hasOrdering,
		id: productId,
		image,
		listingId,
		name,
		pharmaComposition: pharmaComposition,
		pricing: prices,
		pricingOld: pricesOld,
		subCategory: {
			name: subCategoryName
		},
		medical,
		recreational
	}
}

export default destructureMenuProductGQL

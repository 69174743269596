export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatAddress = ({ address, city, state, zip }) => {
	const fullAddress = []
	if (address) { fullAddress.push(capitalize(address)) }
	if (city) { fullAddress.push(capitalize(city)) }
	if (state) { fullAddress.push(capitalize(state)) }
	if (zip) { fullAddress.push(zip) }
	return fullAddress.join(', ')
}

export const formatCityStateString = function (address) {
	const cityName = address?.city
	const stateName = address?.state?.name
	const stateShort = address?.state?.short
	if (!cityName || !stateName || !stateShort) return ''
	if (stateShort === 'DC') return stateName
	return `${cityName}, ${stateShort}`
}

export default { formatAddress, formatCityStateString }

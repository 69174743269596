var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(!_vm.hasSrc || _vm.showFallback)?_c('img',{class:[
			_vm.classes,
			'object-cover w-full h-full'
		],attrs:{"src":_vm.fallbackOrError,"alt":_vm.alt,"width":_vm.width,"height":_vm.height}}):_c('picture',{ref:"thisImage",staticClass:"w-full"},[(_vm.shouldPrependWebp)?[_c('source',{attrs:{"srcset":_vm.srcSetStringWebp,"type":"image/webp"}})]:_vm._e(),_vm._v(" "),_c('img',{class:[
				_vm.classes,
				_vm.objectCover ? 'object-cover w-full h-full' : 'w-full h-auto',
			],attrs:{"src":_vm.builtSrc,"alt":_vm.alt,"width":_vm.width,"height":_vm.height,"fetchpriority":_vm.fetchPriority},on:{"error":_vm.setFallbackImage}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<nav
		class="flex items-center w-full min-h-8"
		aria-label="breadcrumb"
	>
		<div
			v-for="(breadcrumb, index) in breadcrumbs"
			:key="`breadcrumb-${index}`"
			:class="[{ 'sr-only': !!breadcrumb.srOnly }, 'items-center']"
		>
			<router-link
				v-if="breadcrumb && breadcrumb.url"
				:to="breadcrumb.url"
				class="flex items-center text-black transition-all duration-100 ease-in-out rounded group focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
			>
				<img
					v-if="showBack"
					height="16"
					width="16"
					:src="backIcon"
					alt="back icon"
				>
				<div class="group-hover:bg-gray-200 rounded-xl px-2.5 group-hover:underline group-hover:underline-offset-2 transition-all duration-100 ease-in-out">
					{{ breadcrumb.content }}
				</div>
			</router-link>
		</div>
	</nav>
</template>

<script async>
import requireIcon from '@/utils/requireIcon.js'

export default {
	props: {
		breadcrumbs: {
			type: Array,
			required: true
		}
	},
	computed: {
		showBack() {
			return this.displayCount === 1
		},
		displayCount() {
			return this.breadcrumbs.filter(breadcrumb => !breadcrumb.srOnly).length
		},
		backIcon() {
			return this.showBack && requireIcon('back.svg')
		}
	}
}
</script>

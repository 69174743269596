<template>
	<div class="flex flex-col w-full m-auto">
		<Breadcrumbs
			:breadcrumbs="breadcrumbs"
			class="px-3 pt-4 pb-3 text-sm md:pb-4"
		/>
		<BusinessProductHeader
			:product="product"
			:indication="indication"
			:image-url="imageUrl"
			:disable-ordering="disableOrdering"
			:business-has-medical="businessHasMedical"
			:business-has-recreational="businessHasRecreational"
		/>
		<BusinessProductDetails
			:product="product"
			:indication="indication"
			:pharma-composition="pharmaComposition"
		/>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { destructureMenuProductGQL } from '@/api/helpers/destructureMenuProduct.js'
import BusinessProductDetails from '@/components/business/product/BusinessProductDetails.vue'
import BusinessProductHeader from '@/components/business/product/BusinessProductHeader.vue'
import Breadcrumbs from '@/components/multiUse/Breadcrumbs.vue'
import { INDICATION_TYPES } from '@/constants/business/index.js'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { GetBusinessProductPageData } from '@/gql/business/queries/pages.gql'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'
import { pageLevelGQLErrors } from '@/utils/error-handling.js'
import { capitalize, formatCityStateString } from '@/utils/formatText.js'

const { RECREATIONAL, MEDICAL } = INDICATION_TYPES

export default {
	components: {
		Breadcrumbs,
		BusinessProductDetails,
		BusinessProductHeader
	},
	apollo: {
		productPageData: {
			query: GetBusinessProductPageData,
			update(data) {
				if (data.listing) {
					if (data.listing.products?.[0]) { this.setJsonLd(data.listing.products[0]) }
					return {
						...data.listing
					}
				}
				this.$store.commit('setError', { status: 404 })
			},
			variables() {
				return {
					seoUrl: this.$route.params?.business,
					chainSeoUrl: this.$route.params?.chain || '',
					id: this.$route.params?.productId
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			},
			errorPolicy: 'none' // bypass setting the 404 (the update hook) if no results are returned
		}
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: [
				{ description: this.metaDescription },
				{ rel: 'preload', href: this.builtProductImage, as: 'image' }
			]
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	data () {
		return {
			MENU_IMAGES,
			prevRoute: null
		}
	},
	computed: {
		...mapGetters('cart', [ 'cart' ]),
		metaTitle() {
			const {
				indication, product, businessName, cityStateString
			} = this
			return `
				${(indication ? capitalize(indication) : '')}
				${product?.name} near
				${cityStateString} from
				${businessName} - Where's Weed
			`
		},
		metaDescription() {
			const {
				indication, product, businessName, cityStateString, compositionBreakdown
			} = this
			let descriptionText = `
				${(indication ? capitalize(indication) : '')}
				${product?.name}
				${product?.category} in
				${cityStateString} |
			`

			if (compositionBreakdown) {
				descriptionText += `${compositionBreakdown} from `
			}
			descriptionText += `${businessName} on wheresweed.com`

			return descriptionText
		},
		cityStateString() {
			return formatCityStateString(this.productPageData?.address)
		},
		businessName() {
			return this.productPageData?.name
		},
		pharmaComposition() {
			const composition = []
			this.product?.pharmaComposition?.forEach((item, index) => {
				if (item.amount > 0 || item.value > 0) {
					composition.push({
						label: item.label || item.name,
						key: item.key || index,
						amount: item.amount || item.value
					})
				}
			})
			return composition
		},
		compositionBreakdown() {
			const breakdown = []
			this.pharmaComposition.forEach((value) => {
				const label = value.label.toUpperCase()
				const percentage = `${value.amount}%`
				breakdown.push(`${label}: ${percentage}`)
			})
			return breakdown.join(' | ')
		},
		imageUrl() {
			return this.product?.image
		},
		builtProductImage() {
			if (this.imageUrl) {
				return buildImageUrl({
					file: this.imageUrl.split('/').pop(), imagePath: MENU_IMAGES, width: 400, height: 400
				})
			}
			return ''
		},
		breadcrumbs() {
			const crumbs = [ {
				url: '/', content: 'Home', srOnly: true
			} ]

			crumbs.push({
				url: this.breadcrumbRoute, content: `Back to ${this.businessName}`
			})

			return crumbs
		},
		breadcrumbRoute() {
			return this.prevRoute?.name
				? {
					name: this.prevRoute?.name,
					query: this.prevRoute?.query || '',
					hash: `#${this.product?.id}`
				}
				: this.businessUrl
		},
		businessUrl() {
			return this.productPageData?.url
		},
		hasProduct() {
			return !!this.productPageData?.products?.[0]
		},
		product() {
			if (this.hasProduct) {
				return destructureMenuProductGQL(this.productPageData?.products?.[0])
			}
			return {}
		},
		businessHasMedical() {
			return this.productPageData?.settings?.hasMedical
		},
		businessHasRecreational() {
			return this.productPageData?.settings?.hasRecreational || !this.productPageData?.settings?.hasMedical && !this.productPageData?.settings?.hasRecreational
		},
		productHasMedPricing() {
			return this.indication === MEDICAL.value
		},
		productHasRecPricing() {
			return this.indication === RECREATIONAL.value
		},
		productId() {
			return this.$route.params?.productId
		},
		disableOrdering() {
			return (this.productPageData?.settings?.hasMedical && !this.productHasMedPricing) ||
			(this.productPageData?.settings?.hasRecreational && !this.productHasRecPricing)
		},
		indication() {
			return this.product?.pricing?.[0]?.type
		}
	},
	methods: {
		...mapActions('cart', [
			'addToCart',
			'updateCart'
		]),
		...mapMutations('toast', [ 'showToast' ]),
		...mapMutations('modal', [ 'showModal' ]),
		setJsonLd({ name, desc }) {
			this.$store.dispatch('setJsonLd', {
				'@context': 'https://schema.org/',
				'@type': 'Product',
				name: name || '',
				description: desc || ''
			})
		}
	}
}
</script>

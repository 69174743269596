const sanitizePharmaComposition = (pharmaComposition) => {
	const mappedComposition = []
	pharmaComposition?.forEach((item) => { // Hide if amount is 0
		if (item.amount > 0 || item.value > 0) {
			const label = item.label || item.name
			const key = item.key || item.name
			const amount = item.amount || item.value
			const sanitizedLabel = label.replace(/_|-|\./g, ' ') // Replace underscores, dashes, and periods with spaces
			mappedComposition.push({
				label: sanitizedLabel,
				key: key,
				amount: amount
			})
		}
	})
	return mappedComposition
}

export default sanitizePharmaComposition
